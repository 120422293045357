import React, { useEffect } from "react"
import { Layout } from "@/layout"
import { Box, SEO, Heading, Text } from "@/components"

export default function Application() {
  useEffect(() => {
    window.jQuery = window.jQuery || (() => ({}))
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: "6200944",
        formId: "3cae76b0-30fe-41f7-84a4-24e79ca17ba8",
        target: "#hubspotForm",
        onFormSubmitted: function () {
          window.location.href = "/affiliate-schedule"
        },
      })
    }
  }, [])

  return (
    <Layout
      headerType="checkout"
      headerTheme="light"
      footerTheme="light"
      squeeze={true}
    >
      <SEO
        title="Application Form"
        description="Students are selected based on the quality of their application/interview and application date."
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["7.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Box width={["100%", null, null, 5 / 12]} textAlign="center" mx="auto">
          <Text variant="subhead" mt="4rem">
            STEP 1 of 2
          </Text>
          <Heading
            level="1"
            mb="1rem"
            mt="3rem"
            px={["2rem", "2rem", "2rem", "2rem"]}
          >
            Affiliate Application Form
          </Heading>
        </Box>
        <Box width={["90%", null, null, 6 / 12]} mx="auto" mt="3rem">
          <div id="hubspotForm"></div>
        </Box>
      </Box>
    </Layout>
  )
}
